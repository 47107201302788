@use "../../../../shared/breakpoints.scss" as breakpoints;
@use "../../../../shared/styles/mixins/text.scss" as text;
@use "../../../../shared/styles/mixins/text-link-underline.scss" as text-link-underline;

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.link {
  @include text.text;
  @include text.text-medium;
  @include text-link-underline.text-link-underline;

  display: inline-block;
  margin-top: 14px;
  color: inherit;
  letter-spacing: 1.2px;

  &::after {
    background-color: var(--color-light-primary-85);
  }

  @media (min-width: breakpoints.$tablet-portrait) {
    &::after { background-color: var(--color-dark-primary-55); }
  }
}
