@use "../../../shared/breakpoints.scss" as breakpoints;

.root {
  display: flex;
  width: 100%;
  padding: 0 15px;

  @media (min-width: breakpoints.$tablet-portrait) { width: 50%; }

  @media (min-width: breakpoints.$tablet-landscape) { width: 33.33%; }
}
