@mixin dot() {
  position: absolute;
  top: -.2em;
  left: .065em;
  width: .2em;
  height: .2em;
  background: var(--color-accent);
  border-radius: 50%;
  content: "";
}
