@mixin fade-slide-enter {
  animation: fade-in-slide-up ease .8s forwards .3s;
  opacity: 0;

  @keyframes fade-in-slide-up {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:nth-of-type(2) { animation-delay: .5s; }
  &:nth-of-type(3) { animation-delay: .7s; }
  &:nth-of-type(4) { animation-delay: .9s; }
}
