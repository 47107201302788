@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/text.scss" as text;
@use "../../shared/styles/mixins/text-button-underline.scss" as text-button-underline;
@use "../../shared/styles/mixins/animations.scss" as animations;

.buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: .9em;

  @media (min-width: breakpoints.$tablet-portrait) { gap: 15px; }

  @media (min-width: breakpoints.$large-desktop) { gap: 20px; }
}

.button {
  @include text.text;
  @include text.text-button;

  position: relative;
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  letter-spacing: 3.2px;
  outline: none;
  text-shadow: var(--color-text-shadow);

  &.selected {
    &::after {
      @include text-button-underline.text-button-underline;
      @include animations.underline;
    }
  }
}
