@use "../../../shared/breakpoints.scss" as breakpoints;

.root {
  display: grid;
  width: 100%;
  padding-bottom: 80px;
  gap: 40px;
  grid-template:
    "heading"
    "image"
    "body"
    "donationLinks"
    "shareLinks"
    /100%;

  @media (min-width: breakpoints.$tablet-landscape) {
    grid-template:
      "heading       heading"
      "image         body   "
      "donationLinks body   "
      "shareLinks    body   "
      /400px         1fr;
    grid-template-rows: min-content min-content min-content 1fr;
  }

  @media (min-width: breakpoints.$desktop) { padding-bottom: 100px; }
}
