@use "../../../shared/breakpoints.scss" as breakpoints;

.root {
  display: grid;
  padding: 70px 0;
  gap: 70px;

  @media (min-width: breakpoints.$desktop) {
    padding: 100px 0;
    margin-left: 15vw;
    gap: 100px;
  }

  @media (min-width: breakpoints.$large-desktop) { margin-left: 10vw; }
}
