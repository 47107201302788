@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;

.root {
  padding: 90px 20px 110px;

  @media (min-width: breakpoints.$mobile) { padding: 90px 90px 110px; }

  @media (min-width: breakpoints.$tablet-landscape) { padding: 110px 90px 130px; }

  @media (min-width: breakpoints.$desktop) { padding: 130px 0 150px; }
}

.title {
  @include headline.headline;

  margin-bottom: 60px;
  color: var(--color-dark-primary);
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: breakpoints.$mobile) { margin-bottom: 80px; }
}
