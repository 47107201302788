@use "../../../shared/breakpoints.scss" as breakpoints;

.root {
  padding: 0;
  border: 0;
  background-color: unset;
  color: var(--color-dark-primary);
  cursor: pointer;
  transition: var(--common-transition);

  &.darkTheme { color: var(--color-light-primary); }

  &:disabled {
    cursor: auto;
    opacity: .3;
  }

  @media (min-width: breakpoints.$desktop) {
    &:hover:not(:disabled) { opacity: .7; }
  }
}

.icon { font-size: 26px; }
