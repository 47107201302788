@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/text.scss" as text;

.root {
  display: flex;
  width: 100%;
  max-width: 585px;
  flex-direction: column;
  padding: 0 20px 60px;
  margin: 0 auto;
  gap: 5px;

  @media (min-width: breakpoints.$tablet-portrait) {
    max-width: 750px;
    padding: 60px;
  }

  @media (min-width: breakpoints.$tablet-landscape) { margin: 0 0 0 auto; }

  @media (min-width: breakpoints.$desktop) { max-width: 585px; }

  @media (min-width: breakpoints.$large-desktop) { padding: 90px; }
}

.submitGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.submitGroupMessage {
  @include text.text;
  @include text.text-medium;

  min-height: 21px;
  margin: 0;
  text-align: center;
}
