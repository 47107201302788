@use "../../shared/breakpoints.scss" as breakpoints;

.root {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sliderControls {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 16px;
  margin-top: 30px;
  gap: 40px;

  @media (min-width: breakpoints.$mobile) {
    padding-right: 48px;
    margin-top: 40px;
  }

  @media (min-width: breakpoints.$tablet-portrait) {
    padding-right: 0;
  }

  @media (min-width: breakpoints.$desktop) {
    padding-right: calc((100% - 1170px) / 2);
  }

  @media (min-width: breakpoints.$large-desktop) {
    padding-right: calc((100% - 1280px) / 2);
    margin-top: 60px;
  }
}
