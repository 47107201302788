@use "../../../shared/breakpoints.scss" as breakpoints;
@use "../../../shared/styles/mixins/animations.scss" as animations;

@mixin text-link-underline() {
  position: relative;
  text-decoration: none;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
  }

  @media (min-width: breakpoints.$desktop) {
    &::after { width: 0; }

    &:hover::after {
      width: 100%;
      transform-origin: 0;

      @include animations.underline;
    }
  }
}
