@mixin jumping-dot {
  animation: jump .85s infinite;

  @keyframes jump {
    0% { transform: translateY(0) scale(1.25, .75); }
    50% { transform: translateY(-100%) scale(1, 1); }
    55% { transform: translateY(-100%) rotate(15deg); }
    60% { transform: translateY(-100%) rotate(-15deg); }
    65% { transform: translateY(-100%) rotate(15deg); }
    70% { transform: translateY(-100%) rotate(-15deg); }
    100% { transform: translateY(0) scale(1.25, .75); }
  }
}

@mixin underline {
  animation: underline .5s;

  @keyframes underline {
    0% { transform: scaleX(0); }
    100% { transform: scaleX(1); }
  }
}

@mixin throbbing-text() {
  animation: pulsate 3s ease-out infinite;

  @keyframes pulsate {
    0% { font-size: 220px; }
    50% { font-size: 240px; }
    100% { font-size: 220px; }
  }
}
