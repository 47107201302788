@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;
@use "../../shared/styles/mixins/text-button-underline.scss" as text-button-underline;
@use "../../shared/styles/mixins/animations.scss" as animations;

.content {
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoints.$tablet-landscape) { flex-direction: row; }
}

.primaryContent {
  width: 100%;

  @media (min-width: breakpoints.$tablet-landscape) {
    width: 50%;
    min-height: 500px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  padding: 0 15px 40px;
  margin: 0 auto;

  @media (min-width: breakpoints.$tablet-portrait) {
    max-width: 750px;
    padding: 110px 15px 100px;
  }

  @media (min-width: breakpoints.$tablet-landscape) {
    max-width: 585px;
    padding: 110px 15px 100px 40px;
    margin: 0 0 0 auto;
  }

  @media (min-width: breakpoints.$large-desktop) { padding: 110px 15px 100px; }
}

.tabList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 40px;
  gap: 30px;
  list-style: none;

  @media (min-width: breakpoints.$mobile) {
    flex-direction: row;

    // margin-bottom: 40px;
  }

  @media (min-width: breakpoints.$tablet-landscape) {
    margin-bottom: 50px;
    gap: 25px;
  }
}

.tab {
  @include text.text;
  @include text.text-button;

  position: relative;
  color: var(--color-dark-primary-33);
  cursor: pointer;
  outline: none;
  transition: var(--common-transition);

  @media (min-width: breakpoints.$desktop) {
    &:hover { color: var(--color-dark-primary); }
  }

  &.selected {
    color: var(--color-dark-primary);

    &::after {
      @include text-button-underline.text-button-underline;
      @include animations.underline;
    }
  }
}

.tabPanelParagraph {
  @include text.text;
  @include text.text-medium;

  padding: 0;
  margin: 0 0 10px;
  color: var(--color-dark-primary);

  @media (min-width: breakpoints.$tablet-portrait) { margin: 0 0 18px; }

  @media (min-width: breakpoints.$tablet-landscape) { max-width: 388px; }
}

.accentBox {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 40px;
  background-color: var(--color-accent);

  @media (min-width: breakpoints.$mobile) {
    padding: 90px 120px;
  }

  @media (min-width: breakpoints.$tablet-landscape) {
    width: 50%;
    max-width: 585px;
    padding: 90px;
  }

  @media (min-width: breakpoints.$large-desktop) {
    max-width: 640px;
    min-height: 604px;
  }
}
