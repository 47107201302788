@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;

  &::after {
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 4px;
    background-color: var(--color-accent);
    content: "";
  }
}

.icon {
  width: 50px;
  height: 50px;
  align-self: center;
  margin: 0 0 20px;
}

.heading {
  @include headline.headline;
  @include headline.headline-small;

  max-width: 400px;
  margin: 0 0 20px;
  color: var(--color-dark-primary);
  text-align: center;
}

.headingAccent {
  margin-right: 12px;
  color: var(--color-accent);
}

.description {
  @include text.text;
  @include text.text-medium;

  margin: auto 0;
  color: var(--color-dark-primary);
  text-align: justify;

  @media (min-width: breakpoints.$tablet-portrait) { text-align: center; }
}
