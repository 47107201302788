@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/text.scss" as text;

.root {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.text {
  @include text.text;
  @include text.text-medium;
}

.list {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 12px;
  list-style: none;
}

.link {
  display: inline-flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-dark-primary);
  border-radius: 50%;
  color: var(--color-dark-primary);
  font-size: 25px;
  line-height: 1;
  transition: opacity .3s ease;
  transition: var(--common-transition);

  @media (min-width: breakpoints.$desktop) {
    width: 38px;
    height: 38px;
    font-size: 20px;
  }

  @media (min-width: breakpoints.$desktop) {
    &:hover { transform: scale(1.2); }
  }
}
