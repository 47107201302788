@use "../../shared/breakpoints.scss" as breakpoints;

.wrapper {
  position: relative;
  max-width: 100%;
}

.root {
  display: flex;
  align-items: center;
}

.arrow {
  position: absolute;
  top: 50%;
  display: none;
  transform: translateY(-50%);

  @media (min-width: breakpoints.$mobile) { display: flex; }
}

.arrowLeft { left: -55px; }
.arrowRight { right: -55px; }

.progressBar {
  margin-top: 40px;

  @media (min-width: breakpoints.$mobile) {
    max-width: 60%;
    margin: 40px auto 0;
  }

  @media (min-width: breakpoints.$tablet-landscape) {
    margin: 20px auto 0;
  }
}
