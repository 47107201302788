@use "../../shared/breakpoints.scss" as breakpoints;

.content {
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoints.$tablet-landscape) { flex-direction: row; }
}

.formWrapper {
  width: 100%;

  @media (min-width: breakpoints.$tablet-landscape) { width: 50%; }
}

.generalInfoWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 60px 40px;
  background-color: var(--color-accent);

  @media (min-width: breakpoints.$mobile) { padding: 100px 90px; }

  @media (min-width: breakpoints.$tablet-landscape) {
    width: 50%;
    max-width: 585px;
    padding: 90px;
  }

  @media (min-width: breakpoints.$large-desktop) { max-width: 640px; }
}
