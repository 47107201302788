:root {
  --color-light-default: rgb(255 255 255);
  --color-light-primary: rgb(248 248 248);
  --color-light-primary-85: rgb(248 248 248 / 85%);
  --color-dark-primary: rgb(1 1 9 / 90%);
  --color-dark-primary-55: rgb(1 1 9 / 55%);
  --color-dark-primary-33: rgb(1 1 9 / 33%);
  --color-accent: rgb(193 1 5);
  --color-text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
}
