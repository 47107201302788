@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;

.root {
  display: grid;
  grid-template-rows: max-content 1fr;

  @media (min-width: breakpoints.$tablet-portrait) { grid-template-columns: 1fr 1fr; }
}

.imageColumn {
  position: relative;
  width: 100%;
}

.imageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: breakpoints.$tablet-portrait) {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    height: 0;
    padding-bottom: 100%;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: breakpoints.$tablet-portrait) { position: absolute; }
}

.imageColumnContent {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding: 20px;
  color: var(--color-light-default);

  @media (min-width: breakpoints.$mobile) { padding: 60px; }

  @media (min-width: breakpoints.$desktop) { padding: 90px; }

  @media (min-width: breakpoints.$large-desktop) { padding: 110px 90px; }
}

.contentColumn {
  display: flex;
  flex-direction: column;
  padding: 30px 16px 60px;
  background: var(--color-light-default);
  gap: 10px;

  @media (min-width: breakpoints.$tablet-portrait) {
    min-height: 380px;
    justify-content: center;
    padding: 60px 30px 60px 40px;
    gap: 25px;
  }

  @media (min-width: breakpoints.$tablet-landscape) {
    min-height: 585px;
    justify-content: flex-end;
    padding: 90px 60px;
    gap: 60px;
  }

  @media (min-width: breakpoints.$large-desktop) { padding: 110px 90px; }
}

.title {
  max-width: 400px;
  color: var(--color-dark-primary);

  @media (min-width: breakpoints.$tablet-portrait) {
    position: relative;
    margin-left: 25px;

    &::before {
      position: absolute;
      left: -25px;
      color: var(--color-accent);
      content: "|";
    }
  }
}

.subtitle {
  @include headline.headline;
  @include headline.headline-medium;

  color: var(--color-dark-primary-33);

  @media (min-width: breakpoints.$tablet-portrait) { max-width: 400px; }
}
