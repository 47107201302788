@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/text.scss" as text;

.root {
  padding: 30px 0;

  @media (min-width: breakpoints.$mobile) { padding: 40px 30px; }

  @media (min-width: breakpoints.$tablet-portrait) {
    display: flex;
    align-items: center;
  }
}

.qrCode {
  display: none;
  width: 200px;
  margin-right: 40px;

  @media (min-width: breakpoints.$tablet-portrait) { display: block; }
}

.info {
  display: flex;
  flex-direction: column;
}

.text {
  @include text.text;
  @include text.text-medium;

  max-width: 600px;
  margin: 0 0 15px;
  color: var(--color-dark-primary);
}

.credentials {
  @include text.text;
  @include text.text-medium;

  font-weight: 300 !important;
}
