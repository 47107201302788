@use "../../shared/breakpoints.scss" as breakpoints;

.root {
  display: flex;
  width: 90%;
  max-width: 1170px;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  row-gap: 60px;

  @media (min-width: breakpoints.$tablet-portrait) { row-gap: 90px; }
}
