@use "../../breakpoints.scss" as breakpoints;

@mixin headline() {
  margin: 0;
  font-family: poppins, Arial, sans-serif;
  font-style: normal;
  text-transform: uppercase;
}

@mixin headline-xl() {
  font-size: 44px;
  font-weight: 900;
  letter-spacing: -.02em;
  line-height: .8;

  @media (min-width: 414px) { font-size: 56px; }

  @media (min-width: breakpoints.$tablet-portrait) { font-size: 100px; }

  @media (min-width: breakpoints.$tablet-landscape) { font-size: 12vw; }

  @media (min-width: breakpoints.$desktop) { font-size: 9vw; }
}

@mixin headline-large() {
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 1.8;
  line-height: .9;

  @media (min-width: breakpoints.$tablet-portrait) { font-size: 80px; }

  @media (min-width: breakpoints.$tablet-landscape) { font-size: 7vw; }

  @media (min-width: breakpoints.$large-desktop) { font-size: 6vw; }
}

@mixin headline-medium() {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;

  @media (min-width: breakpoints.$tablet-portrait) { font-size: 30px; }

  @media (min-width: breakpoints.$tablet-landscape) { font-size: 36px; }

  @media (min-width: breakpoints.$large-desktop) { font-size: 40px; }
}

@mixin headline-small() {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .04em;
  line-height: 1.5;
}

@mixin headline-accent() {
  font-size: 31.8px;
  letter-spacing: -.02em;

  @media (min-width: 414px) { font-size: 40.5px; }

  @media (min-width: breakpoints.$tablet-portrait) { font-size: 72.5px; }

  @media (min-width: breakpoints.$tablet-landscape) { font-size: 8.7vw; }

  @media (min-width: breakpoints.$desktop) { font-size: 6.5vw; }
}
