@use "../../shared/breakpoints.scss" as breakpoints;

/* TODO: refactor, get rid of .container; avoid list's margin other than 0 */
.container {
  padding: 0 0 70px;

  @media (min-width: breakpoints.$mobile) { padding: 70px 0; }

  @media (min-width: breakpoints.$tablet-portrait) { padding: 90px 0; }

  @media (min-width: breakpoints.$tablet-landscape) { padding: 130px 0; }
}

.root {
  display: flex;
  width: 90%;
  max-width: 750px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  row-gap: 60px;

  @media (min-width: breakpoints.$tablet-portrait) {
    max-width: 1170px;
    row-gap: 90px;
  }
}
