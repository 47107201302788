@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/dot.scss" as dot;
@use "../../shared/styles/mixins/animations.scss" as animations;

.title {
  @include headline.headline;
  @include headline.headline-xl;

  /* title-accent equals difference between main's and accent's font sizes */
  margin-bottom: 15.5px;
  color: var(--color-dark-primary);
  text-shadow: var(--color-text-shadow);

  @media (min-width: breakpoints.$tablet-portrait) { margin-bottom: 27.5px; }

  @media (min-width: breakpoints.$tablet-landscape) { margin-bottom: 3.3vw; }

  @media (min-width: breakpoints.$tablet-landscape) { margin-bottom: 2.5vw; }
}

.titleAccent {
  @include headline.headline-accent;

  display: block;
  margin: 0 0 0 .03em;
  color: var(--color-accent);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 18%);
}

.dot {
  position: relative;
  display: inline-block;
  vertical-align: bottom;

  &::before {
    @include dot.dot;
    @include animations.jumping-dot;
  }
}
