@use "../../shared/styles/mixins/text.scss" as text;

.root {
  margin: 20px 0;
  color: var(--color-dark-primary);
  text-align: justify;

  &.darkTheme { color: var(--color-light-primary); }
}

.text {
  @include text.text;
  @include text.text-medium;

  position: relative;
  padding: 40px 0;
}

.author {
  @include text.text;
  @include text.text-medium;

  display: block;
  margin-top: 15px;
  font-style: italic;
}

.quotesLeft,
.quotesRight {
  position: absolute;
  color: var(--color-dark-primary);
  font-size: 22px;

  &.darkTheme { color: var(--color-light-primary); }
}

.quotesLeft {
  top: 0;
  left: 0;
}

.quotesRight {
  right: 0;
  bottom: 0;
}
