.completed {
  height: 100%;
  background: var(--color-dark-primary);
  transition: var(--common-transition);
}

.root {
  overflow: hidden;
  width: 100%;
  height: 4px;
  border: 1px solid var(--color-dark-primary);
  border-radius: 3px;

  &.darkTheme {
    border: 1px solid var(--color-light-primary);

    & > .completed {
      background: var(--color-light-primary);
    }
  }
}
