@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;

.root {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;

  @media (min-width: breakpoints.$mobile) {
    width: 50%;
    padding-bottom: 50%;
  }

  @media (min-width: breakpoints.$tablet-landscape) {
    width: 25%;
    padding-bottom: 25%;
  }

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-dark-primary);
    content: "";
    opacity: 0;
    transition: opacity 1s ease;
  }

  @media (min-width: breakpoints.$desktop) {
    &:hover::before { opacity: .28; }
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 50%;
  display: flex;
  width: calc(100% - 40px);
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transform: translateX(-50%);
  transition: opacity 1s ease;

  @media (min-width: breakpoints.$desktop) { opacity: 0; }

  @media (min-width: breakpoints.$large-desktop) { bottom: 40px; }
}

@media (min-width: breakpoints.$desktop) {
  .root:hover > .info { opacity: 1; }
}

.name {
  @include headline.headline;

  color: var(--color-light-primary);
  font-size: 22px;
  line-height: 1.2;
  text-align: center;
  text-shadow: var(--color-text-shadow);
  text-transform: uppercase;

  @media (min-width: breakpoints.$large-desktop) {
    @include headline.headline-medium;
  }
}

.nonBreakable {
  white-space: nowrap;
}

.roles {
  @include text.text;
  @include text.text-large;

  color: var(--color-light-primary);
  text-align: center;
  text-shadow: var(--color-text-shadow);
  text-transform: uppercase;
}

.socialIcon {
  margin-left: 8px;
  color: var(--color-light-primary);
  font-size: inherit;
  transition: var(--common-transition);
  vertical-align: text-bottom;

  @media (min-width: breakpoints.$large-desktop) { margin-left: 15px; }

  @media (min-width: breakpoints.$desktop) {
    &:hover { opacity: .7; }
  }
}
