@use "../../../shared/breakpoints.scss" as breakpoints;
@use "../../../shared/styles/mixins/headline.scss" as headline;

.link {
  display: flex;
  max-width: max-content;
  align-items: center;
  padding: 0;
  border: none;
  background-color: unset;
  color: var(--color-text-secondary);
  cursor: pointer;
  gap: 10px;
  outline: none;
  text-decoration: none;
  transition: var(--common-transition);
}

.text {
  @include headline.headline;
  @include headline.headline-small;
}

.arrows {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 24px;
  height: 24px;
  align-items: center;
}

.link.left {
  flex-direction: row-reverse;

  & .arrows {
    transform: scale(-1, 1);
  }
}

.arrow {
  @include headline.headline;
  @include headline.headline-small;

  position: absolute;
  transition: transform .3s ease;

  &:nth-child(2) { transform: translate(-40px); }
}

@media (min-width: breakpoints.$desktop) {
  .link:hover,
  .link:focus { color: var(--color-accent); }
  .link:hover .arrow:first-child { transform: translate(40px); }
  .link:hover .arrow:nth-child(2) { transform: translate(0); }
}
