@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;
@use "../../shared/styles/mixins/text-link-underline.scss" as text-link-underline;

.root {
  display: grid;
  grid-template:
    "navigation"
    "socials   "
    "contacts  "
    "footnote  ";
  row-gap: 46px;

  @media (min-width: breakpoints.$mobile) {
    grid-template:
      "logo       logo     logo    "
      "navigation socials  contacts"
      "footnote   footnote footnote"
      /33%;
    row-gap: 80px;
  }

  @media (min-width: breakpoints.$tablet-landscape) {
    grid-template:
      "logo     navigation socials  contacts"
      "footnote footnote   footnote footnote";
    grid-template-columns: 40% repeat(3, minmax(0, 1fr));
  }

  @media (min-width: breakpoints.$large-desktop) { row-gap: 100px; }
}

.logotype {
  display: none;

  @media (min-width: breakpoints.$mobile) { display: inline; }
}

.navigation { grid-area: navigation; }

.socials { grid-area: socials; }

.contacts { grid-area: contacts; }

.heading {
  @include headline.headline;
  @include headline.headline-small;
}

.footnote {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  border-top: 1px solid var(--color-light-primary);
  margin-top: 30px;
  gap: 40px;
  grid-area: footnote;

  @media (min-width: breakpoints.$mobile) {
    padding: 0;
    border: none;
    margin: 0;
    gap: 14px;
  }

  @media (min-width: breakpoints.$tablet-portrait) { flex-direction: row; }
}

.copyright {
  @include text.text;
  @include text.text-medium;
}

.credits {
  @include text.text;
  @include text.text-medium;

  @media (min-width: breakpoints.$tablet-portrait) {
    position: relative;
    padding-left: 22px;

    &::before {
      position: absolute;
      left: 0;
      content: "||";
    }
  }
}

.link {
  @include text-link-underline.text-link-underline;

  color: inherit;

  &::after { background-color: var(--color-light-primary-85); }

  @media (min-width: breakpoints.$tablet-portrait) {
    &::after { background-color: var(--color-dark-primary-55); }
  }
}
