@use "../../breakpoints.scss" as breakpoints;

@mixin text() {
  margin: 0;
  font-family: poppins, Arial, sans-serif;
  font-style: normal;
}

@mixin text-large() {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: .02em;
  line-height: 1.3;

  @media (min-width: breakpoints.$tablet-landscape) { font-weight: 300; }
}

@mixin text-medium() {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: .02em;
  line-height: 1.3;

  @media (min-width: breakpoints.$tablet-landscape) { font-weight: 200; }
}

@mixin text-small() {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

@mixin text-button() {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.2px;
  line-height: 1.5;
  text-transform: uppercase;

  @media (min-width: breakpoints.$tablet-portrait) { font-size: 18px; }
}
