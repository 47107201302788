@use "../../../shared/breakpoints.scss" as breakpoints;

.root {
  width: 25%;
  width: 100%;
  max-height: 48px;
  padding: 0 25px;

  @media (min-width: breakpoints.$tablet-portrait) {
    width: 50%;
    max-height: 60px;
  }

  @media (min-width: breakpoints.$tablet-landscape) { width: 25%; }
}

.link {
  transition: var(--common-transition);

  @media (min-width: breakpoints.$desktop) {
    &:hover { opacity: .7; }
  }
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
