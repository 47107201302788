@use "../../../shared/breakpoints.scss" as breakpoints;
@use "../../../shared/styles/mixins/headline.scss" as headline;
@use "../../../shared/styles/mixins/text.scss" as text;

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  padding: 20px 0;
  border-top: 1px solid var(--color-dark-primary);
  border-bottom: 1px solid transparent;
  transition: var(--common-transition);

  &:last-of-type {
    border-bottom: 1px solid var(--color-dark-primary);
  }

  @media (min-width: breakpoints.$desktop) {
    padding: 20px;

    &.isActive {
      & .heading,
      .arrowIcon {
        color: var(--color-accent);
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: breakpoints.$desktop) {
    cursor: pointer;

    &:hover .heading,
    &:hover > .arrowIcon {
      color: var(--color-accent);
    }
  }
}

.note {
  @include text.text;
  @include text.text-small;

  color: var(--color-dark-primary);
}

.heading {
  @include headline.headline;
  @include headline.headline-small;

  color: var(--color-dark-primary);
  transition: var(--common-transition);
}

.arrowIcon {
  color: var(--color-dark-primary);
  font-size: 26px;
  transition: var(--common-transition);
}
