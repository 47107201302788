@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;

.heading {
  @include headline.headline;
  @include headline.headline-small;

  margin: 0 16px;
  background-image: linear-gradient(var(--color-dark-primary), var(--color-dark-primary));
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1.5px;
  color: inherit;
  font-size: 18px !important;
  transition: background-size .5s;

  @media (min-width: breakpoints.$mobile) { margin: 0 48px; }

  @media (min-width: breakpoints.$tablet-portrait) { margin: 0; }

  @media (min-width: breakpoints.$desktop) { display: inline; }

  @media (min-width: breakpoints.$large-desktop) {
    background-size: 0% 2px;
  }
}

.link {
  position: relative;
  width: 100%;
  color: var(--color-dark-primary);
  text-decoration: none;

  @media (min-width: breakpoints.$desktop) {
    &:hover .heading {
      background-size: 100% 1.5px;
    }
  }

  @media (min-width: breakpoints.$large-desktop) {
    &:hover .heading {
      background-size: 100% 2px;
    }
  }
}

.imageWrapper {
  position: relative;
  display: block;
  padding-top: 100%;
  margin-bottom: 15px;

  @media (min-width: breakpoints.$tablet-portrait) { padding-top: 87.5%; }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow {
  margin-left: 10px;

  @media (min-width: breakpoints.$desktop) {
    display: none;
  }
}
