@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;
@use "../../shared/styles/mixins/animations.scss" as animations;

.root {
  display: flex;
  min-width: var(--min-content-width);
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  @include headline.headline;

  max-width: calc(100% - 40px);
  margin: 0 auto;
  font-size: 36px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}

.numbers {
  display: flex;
  height: 160px;
  align-items: center;

  @media (min-width: breakpoints.$tablet-portrait) { height: 200px; }

  @media (min-width: breakpoints.$desktop) { height: 280px; }
}

.number {
  @include headline.headline;

  position: relative;
  left: -5px;
  font-size: 120px;
  font-weight: 900;
  letter-spacing: -.09em;
  text-shadow: -4px 2px 1px var(--color-light-primary);

  @media (min-width: breakpoints.$tablet-portrait) { font-size: 160px; }

  @media (min-width: breakpoints.$desktop) {
    @include animations.throbbing-text;

    left: -15px;
    font-size: 220px;
    letter-spacing: -.12em;
    text-shadow: -4.8px 2px 1px var(--color-light-primary);

    &:nth-child(2) { animation-delay: 1s; }
    &:nth-child(3) { animation-delay: 2s; }
  }
}

.link {
  margin-top: 40px;
}
