@use "../../../shared/breakpoints.scss" as breakpoints;

.root {
  position: relative;
  display: block;
  padding-top: 100%;

  @media (min-width: breakpoints.$tablet-portrait) { padding-top: 66.67%; }

  @media (min-width: breakpoints.$large-desktop) {
    padding-top: 66.67%;
    box-shadow: rgb(9 30 66 / 25%) 0 4px 8px -2px, rgb(9 30 66 / 8%) 0 0 0 1px;
  }
}
