@use "../../shared/breakpoints.scss" as breakpoints;

.root {
  display: grid;
  width: 100%;
  padding-bottom: 40px;
  background-color: var(--color-light-default);

  @media (min-width: breakpoints.$mobile) {
    padding-bottom: 60px;
  }

  @media (min-width: breakpoints.$tablet-portrait) {
    max-width: calc(100% - 96px);
    margin: 0 auto;
  }

  @media (min-width: breakpoints.$tablet-landscape) { max-width: unset; }
}
