@use "../../../../shared/breakpoints.scss" as breakpoints;
@use "../../../../shared/styles/mixins/headline.scss" as headline;
@use "../../../../shared/styles/mixins/animations/fade-slide-enter.scss" as animations;

.menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
}

.item.onEnter {
  @include animations.fade-slide-enter;
}

.link {
  @include headline.headline;
  @include headline.headline-small;

  display: inline-block;
  padding: 7px 0;
  color: var(--color-light-primary);
  letter-spacing: 1.2px;
  text-decoration: none;
  text-shadow: var(--color-text-shadow);
  transition: var(--common-transition);

  @media (min-width: breakpoints.$desktop) { &:hover { opacity: .7; } }
}
