@use "../../../shared/breakpoints.scss" as breakpoints;

.root {
  position: fixed;
  z-index: 3;
  right: 20px;
  bottom: 30px;
  box-shadow: rgb(0 0 0 / 20%) 0 2px 8px;

  @media (min-width: breakpoints.$tablet-portrait) {
    right: 48px;
    bottom: 45px;
  }

  @media (min-width: breakpoints.$desktop) {
    right: 30px;
    bottom: 30px;
  }
}
