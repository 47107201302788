@use "../../shared/breakpoints.scss" as breakpoints;
@use "../../shared/styles/mixins/headline.scss" as headline;
@use "../../shared/styles/mixins/text.scss" as text;

.root {
  display: flex;
  flex-direction: column;
  padding: 80px 20px 50px;
  gap: 80px;

  @media (min-width: breakpoints.$tablet-portrait) { padding: 140px 30px 50px 40px; }

  @media (min-width: breakpoints.$tablet-landscape) {
    height: 100%;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 60px;
    gap: 20vw;
  }
}

.footnote {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.footnoteHeading {
  @include text.text;
  @include text.text-medium;

  margin-bottom: 9px;
  color: var(--color-light-primary);
}
