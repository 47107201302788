.root {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: var(--color-accent);
  opacity: 0;
  transition: all .5s ease;
  visibility: hidden;
}

.root.isOpen {
  z-index: 4;
  opacity: 1;
  visibility: visible;
}
