@font-face {
  font-display: swap;
  font-family: poppins;
  font-style: normal;
  font-weight: 200;
  src:
    url("/public/fonts/Poppins/Poppins-ExtraLight.woff2") format("woff2"),
    url("/public/fonts/Poppins/Poppins-ExtraLight.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: poppins;
  font-style: normal;
  font-weight: 300;
  src:
    url("/public/fonts/Poppins/Poppins-Light.woff2") format("woff2"),
    url("/public/fonts/Poppins/Poppins-Light.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: poppins;
  font-style: normal;
  font-weight: 400;
  src:
    url("/public/fonts/Poppins/Poppins-Regular.woff2") format("woff2"),
    url("/public/fonts/Poppins/Poppins-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: poppins;
  font-style: normal;
  font-weight: 700;
  src:
    url("/public/fonts/Poppins/Poppins-Bold.woff2") format("woff2"),
    url("/public/fonts/Poppins/Poppins-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: poppins;
  font-style: normal;
  font-weight: 900;
  src:
    url("/public/fonts/Poppins/Poppins-Black.woff2") format("woff2"),
    url("/public/fonts/Poppins/Poppins-Black.woff") format("woff");
}
