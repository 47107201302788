@use "../../../shared/styles/mixins/text.scss" as text;

.root {
  @include text.text;
  @include text.text-medium;

  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--color-dark-primary-33);
  outline: none;
  resize: vertical;
}

.root::placeholder {
  @include text.text;
  @include text.text-medium;

  color: var(--color-dark-primary-55);
  text-transform: uppercase;
}

.inputError {
  @include text.text;
  @include text.text-small;

  min-height: 21px;
  margin: 2px 0 0 15px;
  color: var(--color-accent);
}
